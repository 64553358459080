import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pl-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  constructor() { }

  ngOnInit() {}

}
