export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAlH_D3fBTN3o7TJUvqZ0ueJEBZ4iNZncY',
    authDomain: 'predictor-league-5138e.firebaseapp.com',
    databaseURL: 'https://predictor-league-5138e-default-rtdb.firebaseio.com',
    projectId: 'predictor-league-5138e',
    storageBucket: 'predictor-league-5138e.appspot.com',
    messagingSenderId: '706889377444',
    appId: '1:706889377444:web:18497e7836c669c291a831',
    measurementId: 'G-PNJHM4HDZH'
  }
};
