import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAuthProvider } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly userKey = 'pl_user';

  constructor(private afAuth: AngularFireAuth, public router: Router) {}

  logout() {
    localStorage.removeItem(this.userKey);
    this.router.navigate(['/login']);
  }

  googleAuth() {
    return this.authLogin(new GoogleAuthProvider());
  }

  emailLogin(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.loginSuccess(result);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  emailSignup(email: string, password: string) {
    console.log(email, password);
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.loginSuccess(result);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  resetPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  authLogin(provider: any) {
    return this.afAuth.signInWithPopup(provider)
    .then((result) => {
        this.loginSuccess(result);
    }).catch((error) => {
        console.error(error);
    });
  }

  isAuthenticated() {
    return !!localStorage.getItem(this.userKey);
  }

  loginSuccess(user) {
    localStorage.setItem(this.userKey, JSON.stringify(user.additionalUserInfo?.profile));
    this.router.navigate(['']);
  }

  getUser(): User {
    const user = localStorage.getItem(this.userKey);
    return user ? JSON.parse(user) : null;
  }
}

export interface User {
  email: string;
  family_name: string;
  given_name: string;
  id: string;
  locale: string;
  name: string;
  picture: string;
}
