import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'pl-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;

  constructor(private authService: AuthService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: '',
      password: ''
    });
  }

  googleLogin() {
    this.authService.googleAuth();
  }

  emailLogin() {
    this.authService.emailLogin(this.loginForm.value.email, this.loginForm.value.password);
  }

  emailSignup() {
    this.authService.emailSignup(this.loginForm.value.email, this.loginForm.value.password);
  }

  log() {
    console.log(this.loginForm.value.email, this.loginForm.value.password);
  }

}
